<template>
        <div id="History">
            <b-card>
                <b-form-group
                    label="Buscar"
                    label-for="search"
                >
                    <b-form-input
                        id="search"
                        placeholder="Buscar"
                        v-model="search"
                    />
                </b-form-group>
                <b-form-checkbox
                    id="activos"
                    v-model="actives"
                    name="activos"                    
                >
                    Activos
                </b-form-checkbox>                
            </b-card>
            <b-card>                       
            <ul class="w-full">
            <template v-for="(el, k) in history">
                <li :key="k" v-if="(actives && el.active || !actives)">
                    <article class="mb-4" v-if="searchMethod(el.title)">                        
                        <div class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative p-2 sm:p-5 xl:p-6 overflow-hidden">
                            <h3 class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0 pl-1">{{el.title}}</h3>
                            <time :datetime="Date(el.time)" class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0">
                                <svg viewBox="0 0 12 12" :class="[el.active ? 'red' : '']" class="w-3 h-3 mr-6 overflow-visible text-cyan-400 blob"><circle cx="6" cy="6" r="6" fill="currentColor"></circle><circle cx="6" cy="6" r="11" fill="none" stroke="currentColor" stroke-width="2"></circle><path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path></svg>
                                {{timeParsed(el.time)}}
                            </time>
                            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0 pl-1">
                                <p v-html="findLink(el.code,el.message)" class="info-error"></p>
                            </div>                            
                        </div>                        
                        <div class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative px-3 sm:px-5 xl:px-6 pl-1" >
                            <div class="md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 ml-9 md:ml-0 flex gap-4 align-items-center">
                                <b-button variant="primary" @click="$router.push('/error/'+ el.code)">Instrucciones</b-button>
                                <b-button variant="success" @click="resolveProblem(el.key)" v-if="el.active">Resolver</b-button>
                                <span class="opacity-50">Key: {{el.key}}</span>
                            </div>
                        </div>
                    </article>
                </li>
            </template>
        </ul>                
    </b-card> 
        </div>      
</template>

<script>
import {BCard, BButton, BFormGroup, BFormInput, BFormCheckbox} from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/database'
export default {
    name: 'AlertsHistory',
    data (){
        return {
            historyData: [],
            sortBy: 'time',
            sortDirection: 'asc',
            actives: 0,
            search: ''
        }
    },
    components: {
        BCard,BButton,BFormGroup,BFormInput,BFormCheckbox
    },
    created(){
        firebase
        .database().ref('logErr').limitToLast(25).on('value', (snapshot)=>{
            this.historyData = []  
            Object.keys(snapshot.val()).map((l, k)=>{
                this.historyData.push(snapshot.val()[l])
                this.historyData[k].key = l
            })
            this.sort('time')         
        })                          
    },
    methods:{
        timeParsed(time){  
            if (!time) return 0
            const d = new Date(time)
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            let tm = new Intl.DateTimeFormat('en', { hour: 'numeric', minute :'numeric', second: 'numeric' }).format(d);
            return `${mo} ${da}, ${ye} \n ${tm}`
        },
        sort: function(s){
            if(s === this.sortBy) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            }
            this.sortBy = s;
        },
        resolveProblem(k){
            firebase.database().ref('logErr/'+k+'/active').set(0)
        },
        findLink(code, msj){
            const vc = '['+ code +'] ' + msj
            const link = vc.substring(
                vc.indexOf(">>")+2,
                vc.lastIndexOf("<<")
            );            
            const txt = vc.split(">>")[0]
            let result = vc
            if(link.length > 16) result = txt + '<a href="' + link + '">Ver Nodo</a>'
            return result
        },
        searchMethod(page) {
        const elementToVerify = page.toUpperCase()
        const searchText = this.search ? this.search.toUpperCase() : ''
        if (searchText) {
          if (elementToVerify.indexOf(searchText) !== -1) {
            return true
          }
          return false
        }
        return true
      }
    },
    computed:{
        history: function(){
            return this.historyData.sort((p1,p2) => {
                let modifier = 1;
                this.sortDirection = 'desc'
                if(this.sortDirection === 'desc') modifier = -1;
                if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                return 0;
            });
        }
    }
}
</script>
<style>
.info-error a{
    color: #fe7018;
    font-weight: bold;
    border: 1px #fe7018 dotted;
    padding: 2px 2px;
}
.info-error a:hover{
    color: #fff;    
    background: #fe7018;        
}
</style>
<style scoped>
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);  
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
  color: rgba(199, 24, 24, 1);
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
</style>